import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MainLayout from "@/layouts/MainLayout";
import PageLayout from "@/layouts/PageLayout";
import role from "@/morejs/roles";

Vue.use(VueRouter)

export const routes = [
    //这个位置不要变动, 变动需要修改MainLayout逻辑
    {
        path: '/console',
        component: MainLayout,
        children: [
            {
                path: 'home',
                name: 'home',
                component: Home,
                icon: 'el-icon-house',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '首页',
                        en: 'Home',
                    }
                },
            },
            {
                path: 'user',
                name: 'user',
                component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
                icon: 'el-icon-user',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '用户管理',
                        en: 'User'
                    }
                },
            },
            {
                path: 'mail',
                name: 'mail',
                component: () => import(/* webpackChunkName: "mail" */ '../views/Mail.vue'),
                icon: 'el-icon-message',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '邮箱管理',
                        en: 'Email'
                    }
                },
            },
            {
                path: 'history',
                name: 'history',
                component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
                icon: 'el-icon-download',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '邮箱记录',
                        en: 'Mailbox Record'
                    }
                },
            },
            {
                path: 'mail_cookie',
                name: 'mail_cookie',
                component: () => import(/* webpackChunkName: "mail_cookie" */ '../views/MailCookie.vue'),
                icon: 'el-icon-coin',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '邮箱CK',
                        en: 'Email Cookie'
                    }
                },
            },
         /*   {
                path: 'imap_mail_file',
                name: 'imap_mail_file',
                component: () => import(/!* webpackChunkName: "imap_mail_file" *!/ '../views/ImapMailFile.vue'),
                icon: 'el-icon-upload2',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: 'IMAP导入记录',
                        en: 'IMAP Import Records'
                    }
                },
            },*/
           /* {
                path: 'imap_mail',
                name: 'imap_mail',
                component: () => import(/!* webpackChunkName: "imap_mail" *!/'../views/ImapMail.vue'),
                icon: 'el-icon-box',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: 'IMAP邮箱',
                        en: 'IMAP Email'
                    }
                },
            },*/
            {
                path: 'mail_link',
                name: 'mail_link',
                component: () => import(/* webpackChunkName: "mail_link" */ '../views/MailLink.vue'),
                icon: 'el-icon-link',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '链接记录',
                        en: 'Link Records'
                    }
                },
            },
            {
                path: 'link_task',
                name: 'link_task',
                component: () => import(/* webpackChunkName: "link_task" */ '../views/LinkTask.vue'),
                icon: 'el-icon-paperclip',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '链接任务',
                        en: 'Link Task'
                    }
                },
            },
            {
                path: 'api_task',
                name: 'api_task',
                component: () => import(/* webpackChunkName: "api_task" */ '../views/ApiTask.vue'),
                icon: 'el-icon-document',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: 'API任务',
                        en: 'API Task'
                    }
                },
            },
            {
                path: 'oauth_record',
                name: 'oauth_record',
                component: () => import(/* webpackChunkName: "api_task" */ '../views/OauthRecord.vue'),
                icon: 'el-icon-connection',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '授权任务',
                        en: 'OAuth Record'
                    }
                },
            },
            {
                path: 'capital',
                name: 'capital',
                component: () => import(/* webpackChunkName: "capital" */ '../views/Capital.vue'),
                icon: 'el-icon-money',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '资金记录',
                        en: 'Capital Records'
                    }
                },
            },
            {
                path: 'recharge',
                name: 'recharge',
                component: () => import(/* webpackChunkName: "capital" */ '../views/Recharge.vue'),
                icon: 'el-icon-tickets',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '充值记录',
                        en: 'Recharge Records'
                    }
                },
            },
            {
                path: 'phone',
                name: 'phone',
                component: () => import(/* webpackChunkName: "phone" */ '../views/Phone.vue'),
                icon: 'el-icon-mobile-phone',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '手机接码',
                        en: 'Phone Number'
                    }
                },
            },
            {
                  path: 'mail_account',
                  name: 'mail_account',
                  component: () => import(/* webpackChunkName: "mail_account" */ '../views/MailAccount.vue'),
                  icon: 'el-icon-folder-opened',
                  roles: [role.ADMIN, role.SYSTEM],
                  meta: {
                      title: {
                          zh: '成品邮箱账号',
                          en: 'Mailbox Account'
                      }
                  },
              },
            {
                path: 'type',
                name: 'type',
                component: () => import(/* webpackChunkName: "type" */ '../views/Type.vue'),
                icon: 'el-icon-notebook-1',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '业务类型',
                        en: 'Business Type'
                    }
                },
            },
            {
                path: 'oauth',
                name: 'edit_oauth',
                component: () => import(/* webpackChunkName: "type" */ '../views/Oauth.vue'),
                icon: 'el-icon-notebook-2',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '授权类型',
                        en: 'Oauth Type'
                    }
                },
            },
            {
                path: 'mailbox_type',
                name: 'mailbox_type',
                component: () => import(/* webpackChunkName: "type" */ '../views/MailAccountType.vue'),
                icon: 'el-icon-receiving',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '邮箱类型',
                        en: 'Mailbox Type'
                    }
                },
            },
            {
                path: 'imap_server',
                name: 'imap_server',
                component: () => import(/* webpackChunkName: "imap_server" */ '../views/ImapServer.vue'),
                icon: 'el-icon-monitor',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: 'IMAP服务器',
                        en: 'Imap Server'
                    }
                },
            },
            {
                path: 'setting',
                name: 'setting',
                component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue'),
                icon: 'el-icon-cpu',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '系统配置',
                        en: 'System Config'
                    }
                },
            },
            {
                path: 'config',
                name: 'config',
                component: () => import(/* webpackChunkName: "config" */ '../views/Config.vue'),
                icon: 'el-icon-setting',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '脚本配置',
                        en: 'Script Config'
                    }
                },
            },
            {
                path: 'notice',
                name: 'edit_notice',
                component: () => import(/* webpackChunkName: "config" */ '../views/notice/Edit.vue'),
                icon: 'el-icon-bell',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '平台公告',
                        en: 'Notice'
                    }
                },
            },
            {
                path: 'query',
                name: 'query',
                component: () => import(/* webpackChunkName: "config" */ '../views/Query.vue'),
                icon: 'el-icon-search',
                roles: [role.ADMIN, role.SYSTEM],
                meta: {
                    title: {
                        zh: '查询工具',
                        en: 'Query'
                    }
                },
            },
            {
                path: 'oauth_api',
                name: 'oauth_api',
                component: () => import(/* webpackChunkName: "config" */ '../views/OauthApi.vue'),
                icon: 'el-icon-document',
                roles: [role.ADMIN, role.SYSTEM, role.INSIDER, role.DEFAULT],
                meta: {
                    title: {
                        zh: '授权API',
                        en: 'OAuth Api'
                    }
                },
            },
        ]
    },
    {
        path: '/',
        component: PageLayout,
        children: [
            {
                path: '',
                name: 'url',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/Index'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: 'URL临时邮箱',
                        en: 'Temporary URL',
                    }
                },
            },
            {
                path: 'used',
                name: 'used',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/Used'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '二手邮箱',
                        en: 'Used Gmail'
                    }
                },
            },
            {
                path: 'oauth',
                name: 'oauth',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/Oauth'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '邮箱授权',
                        en: 'Email OAuth'
                    }
                },
            },
            {
                path: 'mailbox',
                name: 'mailbox',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/Mailbox'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '邮箱授权',
                        en: 'Email OAuth'
                    }
                },
            },
            {
                path: 'url/detail/:id',
                name: 'url_detail',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/UrlDetail'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: 'URL详情',
                        en: 'Url Detail'
                    }
                },
            },
            {
                path: 'mailbox/:id',
                name: 'mailbox_detail',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/MailboxDetail'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '邮箱详情',
                        en: 'Mailbox Detail'
                    }
                },
            },
            {
                path: 'oauth/detail/:id',
                name: 'oauth_detail',
                component: () => import(/* webpackChunkName: "store" */ '../views/shop/Index'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '授权详情',
                        en: 'Auth Detail'
                    }
                },
            },
            {
                path: 'notice',
                name: 'notice',
                component: () => import(/* webpackChunkName: "store" */ '../views/notice/Index'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '公告',
                        en: 'Notice',
                    }
                },
            },
            {
                path: 'notice/:id',
                name: 'notice_detail',
                component: () => import(/* webpackChunkName: "store" */ '../views/notice/Detail'),
                icon: 'el-icon-house',
                meta: {
                    title: {
                        zh: '公告详情',
                        en: 'Notice detail',
                    }
                },
            },
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
                meta: {
                    title: {
                        zh: '登录',
                        en: 'Log in'
                    }
                },
            },
        ]
    },
    {
        path: '/pay',
        name: 'pay',
        component: () => import(/* webpackChunkName: "404" */ '../views/shop/Pay.vue'),
        meta: {
            title: {
                zh: '付款',
                en: 'Pay'
            }
        },
    },
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
