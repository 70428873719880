import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import axios from '@/morejs/axios'
import store from '@/store'
import VueI18n from 'vue-i18n';
import en from '@/assets/lang/en'
import zh from '@/assets/lang/zh'

import Page from "@/components/Page";

Vue.component('Page', Page);

Vue.use(ElementUI)
Vue.use(VueI18n);

const messages = {
    en, zh
};

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'en', // 设置默认语言
    messages, // 设置语言包
});

Vue.config.productionTip = false
Vue.prototype.$axios = axios

router.beforeEach((to, from, next) => {
    if (to.path?.startsWith('/console')) {
        const token = localStorage.getItem('token')
        if (!token) {
            next({path: 'login?redirect=' + to.path})
            ElementUI.Message.warning('Please log in first')
            return
        }
    }
    next();
});

new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
