<template>
  <div class="announcement-container" ref="scrollContainer">
    <!-- 克隆的最后一组，放在顶部 -->
    <div
        v-for="(item, index) in list"
        :key="'top-' + index"
        class="announcement-item"
    >
      <router-link :to="'/notice/' + item.id" class="link" target="_blank">
        {{ item['title_' + $i18n.locale] }}
      </router-link>
    </div>
    <!-- 原始公告内容 -->
    <div @mouseenter="mouseenter" @mouseleave="mouseleave"
         v-for="(item, index) in list"
         :key="'main-' + index"
         class="announcement-item"
    >
      <router-link :to="'/notice/' + item.id" class="link" target="_blank">
        {{ item['title_' + $i18n.locale] }}
      </router-link>
    </div>
    <!-- 克隆的第一组，放在底部 -->
    <div
        v-for="(item, index) in list"
        :key="'bottom-' + index"
        class="announcement-item"
    >
      <router-link :to="'/notice/' + item.id" class="link" target="_blank">
        {{ item['title_' + $i18n.locale] }}
      </router-link>
    </div>

    <i class="el-icon-circle-close close" @click="close"></i>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: []
    }
  },
  data() {
    return {
      interval: null,
      scrollSpeed: 1, // 滚动速度（像素/帧）
      pauseDuration: 3000, // 每条公告停留时间（毫秒）
      isPaused: false, // 是否处于暂停状态
      timer: null,
    };
  },
  mounted() {
    this.initScroll();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    close() {
      this.$emit('on-close')
    },
    initScroll() {
      const container = this.$refs.scrollContainer;

      // 初始滚动到中间内容的开头
      container.scrollTop = container.scrollHeight / 3;

      // 开始滚动
      this.interval = setInterval(this.autoScroll, 16); // 每帧滚动
    },
    autoScroll() {
      if (this.isPaused) return;

      const container = this.$refs.scrollContainer;

      // 滚动位置增加
      container.scrollTop += this.scrollSpeed;

      // 如果到达一条公告的边界，暂停滚动
      const currentOffset = container.scrollTop % 40; // 每条公告高度为30px
      if (currentOffset === 0) {
        this.pauseScrolling();
      }

      // 如果到达克隆的第一组（底部），跳回原始内容的顶部
      if (container.scrollTop >= (container.scrollHeight * 2) / 3) {
        container.scrollTop = container.scrollHeight / 3;
      }

      // 如果到达克隆的最后一组（顶部），跳回原始内容的底部
      if (container.scrollTop <= container.scrollHeight / 3 - container.offsetHeight) {
        container.scrollTop = container.scrollHeight / 3;
      }
    },
    mouseenter() {
      this.isPaused = true;
      clearTimeout(this.timer)
    },
    mouseleave() {
      this.pauseScrolling()
    },
    pauseScrolling() {
      this.isPaused = true;
      this.timer = setTimeout(() => {
        this.isPaused = false;
      }, this.pauseDuration);
    },
  },
};
</script>

<style scoped>
.announcement-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px; /* 每条公告高度 */
  background: url(../assets/img_banner3.svg) transparent no-repeat 50%;
  background-size: cover;
  margin: 0 auto;
  color: #000;
  font-family: SourceHanSansCN-Medium;
  line-height: 40px;
}

.announcement-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 设置显示的行数 */
  -webkit-box-orient: vertical; /* 指定盒子的方向 */
  padding: 0 35px;
  text-align: center;
}

.close {
  position: fixed;
  right: 10px;
  top: 10px;
  color: red;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  color: #409EFF;
}

</style>
