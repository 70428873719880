<template>
  <div style="display: flex;flex-direction: column;min-height: 100vh">
    <div class="header-wrapper">
      <notice @on-close="close" v-show="showNotice" :list="notices"/>
      <div class="header-content">
       <router-link to="/" title="G2mail" style="text-decoration: none;color: inherit"  v-if="!mobile">
         <div class="logo-wrapper">
           <img :src="logo1" alt="G2" height="50">
           <b style="margin-left: 10px">G2mail</b>
         </div>
       </router-link>
        <i class="el-icon-menu" style="font-size: 1.5rem;color: rgba(0,0,0,0.7)" v-if="mobile"
           @click="drawer = true;"></i>
        <div class="nav-wrapper">
          <template v-if="!mobile">
            <template v-for="route of routes">
              <router-link class="nav" :class="[$route.name===route.name ? 'nav-select' :'']" :to="{name: route.name}"
                           :key="route.name">{{ route.title }}
              </router-link>
            </template>

            <div class="nav-gap"></div>


          </template>
          <router-link class="page-opt" :class="[$route.name==='notice' ? 'page-opt-select' :'']"
                       :to="{name: 'notice'}" :title="$t('notice')">
            <i class="el-icon-bell"></i>
          </router-link>
          <el-dropdown trigger="click" placement="bottom" @command="changeLanguage">
            <span class="el-dropdown-link page-opt" style="font-size: 0.8rem">
              {{ getLangName($i18n.locale) }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="zh">中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <template v-if="$store.state.userInfo.username">
            <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link page-opt">
              {{ $store.state.userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <router-link :to="{name: 'home'}" style="text-decoration: none">
                  <el-dropdown-item>{{ $t('console') }}</el-dropdown-item>
                </router-link>
                <div @click="logout">
                  <el-dropdown-item>{{ $t('quit') }}</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <router-link :to="{name: 'login'}" class="page-opt">
              <el-button type="primary" plain size="small">{{ $t('login') }}</el-button>
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <div style="opacity: 0;user-select: none;height: 40px" v-show="showNotice"></div>
    <div style="opacity: 0;user-select: none;height: 74px"></div>
    <div class="page-main">
      <router-view/>
    </div>
    <footer class="page-footer">

      <div class="footer-content">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <div style="padding:10px">
              <div class="logo-wrapper">
                <img :src="logo2" alt="G2" height="50">
                <b style="margin-left: 10px">G2mail</b>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <div style="font-size: 0.8em;padding:10px">
              {{ $t('footer_desc') }}
            </div>
          </el-col>
          <el-col :xs="11" :sm="6" :md="6" :lg="6">
            <div style="display: flex;justify-content: flex-end">
              <div class="link-wrapper">
                <a href="https://t.me/G2linghangyuan1" target="_blank" class="link">
                  <i class="el-icon-s-promotion link-logo"></i>
                  <span class="link-text">{{ $t('business') }}</span>
                </a>

                <a href="https://t.me/+Tq4_m1UrpXQ1NGI1" target="_blank" class="link">
                  <i class="el-icon-s-promotion link-logo"></i>
                  <span class="link-text">{{ $t('channels') }}</span>
                </a>

                <a target="_blank" class="link">
                  <i class="el-icon-message link-logo"></i>
                  <span class="link-text">support@g2mail.shop</span>
                </a>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="copyright">Copyright© 2024 G2mail All rights reserved.</div>
    </footer>

    <el-drawer
        v-if="mobile"
        size="70%"
        :visible.sync="drawer"
        direction="ltr">
      <div class="logo">
        <img :src="logo1" :alt="$t('title')" width="40">
        <b style="margin-left: 10px">{{ $t('title') }}</b>
      </div>
      <div @click="drawer=false">
        <template v-for="route of routes">
          <router-link class="mobile-nav-item" :class="[$route.name===route.name ? 'mobile-select-nav' :'']"
                       :to="{name: route.name}"
                       :key="route.name">{{ route.title }}
          </router-link>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import logo1 from '../assets/logo.png'
import logo2 from '../assets/logo2.png'
import Notice from "@/components/Notice";

export default {
  components: {Notice},
  name: 'PageLayout',
  data() {
    return {
      logo1,
      logo2,
      routes: [],
      mobile: false,
      drawer: false,
      showNotice: true,
      notices: [],
    }
  },
  created() {
    this.mobile = document.body.clientWidth <= 768
    this.$store.commit('isMobile', this.mobile)
    this.getRoutes()
    this.getNotice()
  },
  mounted() {
    this.current()
  },
  methods: {
    getRoutes() {
      this.routes = [
        {title: this.$t('url_title'), name: 'url'},
        {title: this.$t('used_title'), name: 'used'},
        {title: this.$t('oauth_title'), name: 'oauth'},
        {title: this.$t('mailbox_title'), name: 'mailbox'},
      ]
    },
    current() {
      this.$axios.get('/user/current').then(res => {
        if (res.code === 0) {
          this.$store.commit('setUserInfo', res.data)
        }
      })
    },
    getLangName(lang) {
      switch (lang) {
        case 'en':
          return 'English';
        case 'zh':
          return '中文';
      }
    },
    changeLanguage(lang) {
      const current = localStorage.getItem('locale');
      if (current === lang) return
      this.$i18n.locale = lang;
      this.getRoutes()
      localStorage.setItem('locale', lang);
    },
    logout() {
      this.$axios.delete('/user/logout').then(() => {
      }).finally(() => {
        localStorage.removeItem('token')
        this.$store.commit('setUserInfo', {})
      })
    },
    close() {
      this.showNotice = false
    },
    getNotice() {
      this.$axios.get('/notice', {params: {page: 1, limit: 5, priority: -1}}).then(res => {
        if (res.code === 0) {
          this.notices = res.data.rows
        }
      })
    }
  }
}
</script>

<style scoped>
.header-wrapper {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.07);
  top: 0;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.nav-wrapper {
  display: flex;
}

.nav {
  text-decoration: none;
  color: #8cc4fc;
  line-height: 70px;
  margin: 0 1rem;
  border-bottom: 2px solid transparent;
}

.nav-select {
  border-bottom: 2px solid #409EFF;
  color: #409EFF;
}

.page-main {
  max-width: 1200px;
  width: 100%;
  margin: 5px auto 0 auto;
  flex: 1;
}

.page-opt {
  line-height: 70px;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 1.5rem;
}

.page-opt:hover {
  cursor: pointer;
  color: #8cc4fc;
}

.page-opt-select {
  color: #409EFF;
}

.nav-gap {
  position: relative;
  width: 1px;
  height: 70px;
  padding: 0 1rem;
}

.nav-gap:before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  width: 1px;
  height: 16px;
  background: #ebebeb;
}

.mobile-nav-item {
  height: 40px;
  font-size: 14px;
  padding-left: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  margin: 5px;
}

.mobile-nav-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
}

.mobile-select-nav {
  background-color: #e6f4ff !important;
  color: #1677ff;
}

.page-footer {
  margin-top: 50px;
  background-color: #10131a;
  padding: 20px 0 10px 0;
}

.link-wrapper {
  display: flex;
  flex-direction: column;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  color: #ffffff;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  margin: 10px 0;
}

.link:hover {
  color: #409EFF;
}

.link-logo {
  padding: 5px;
  border-radius: 50%;
  background-color: #409EFF;
  height: 0.7rem;
  width: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: #ffffff;
}

.link-text {
  margin-left: 5px;
  font-size: 0.8rem;
}

.copyright {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  padding: 10px 0;
  user-select: none;
}
</style>

<style>
.col-gap {
  padding: 10px;
}
</style>
